<template>
  <v-dialog v-model="isCancelModalOpen" persistent max-width="500px">
    <v-card>
      <v-card-title class="text-h3 font-weight-medium">
        {{ $t("supplyOrders.cancel_order_title") }}</v-card-title
      >

      <v-card-text>
        <span class="text-h5">{{ $t("cancel_order_text") }} </span>
        <v-spacer></v-spacer>
        <v-form ref="form" v-model="valid">
          <v-text-field
            outlined
            v-model="reason_cancellation"
            class="mt-4"
            :label="$t('reason_cancellation')"
            :rules="reasonCancellationRules"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="ma-2" @click="close()" color="indigo" text>
          {{ $t("supplyOrders.refuse_cancellation") }}
        </v-btn>
        <v-btn
          class="primary"
          :loading="isLoading"
          :disabled="isLoading"
          @click="confirm()"
          text
        >
          {{ $t("supplyOrders.confirm_cancellation") }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";
export default {
  mixins: [validationRules],
  props: {
    isCancelModalOpen: Boolean,
    toggleCancelModal: Function,
    orderItem: Object,
  },
  data() {
    return {
      valid: false,
      isLoading: false,
      reason_cancellation: "",
      reasonCancellationRules: [
        (v) => !!v || "The cancellation reason is required",
        (v) =>
          (v && v.length <= 25) ||
          "Please write something less than 25 characters",
      ],
    };
  },
  mounted: function () {
    this.currentOrderItem = { ...this.orderItem };
  },
  methods: {
    close() {
      this.toggleCancelModal();
    },
    async confirm() {
      try {
        this.validate();
        if (this.valid) {
          this.isLoading = true;
          await this.$store.dispatch("supplyOrders/cancel", {
            supply_order_id: this.currentOrderItem.id,
            reason_cancellation: this.reason_cancellation,
          });
          this.close();
          this.$store.dispatch(
            "alerts/success",
            this.$t("Order Cancellend successfully")
          );
          if (this.currentOrderItem.status == "confirmed")
            await this.$store.dispatch("supplyOrders/list", {
              supply_order_status: "confirmed",
            });
          else
            await this.$store.dispatch("supplyOrders/list", {
              supply_order_status: "sent",
            });
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>
